import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter);
const router = new VueRouter({
   mode: 'history',
   base: '/',
   routes: [
      {
         path: '/authenticate',
         name: 'login',
         component: () => import('@/views/Auth.vue'),
      },
      
      {
         path: '/',
         name: 'frontpage',
         component: () => import('@/views/Frontpage.vue'),

      }
   ]
});
export default router;
