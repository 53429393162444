import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				// Base
				base: '#393832',

				// Theme
				primary: '#472449',
				secondary: '#bfe5e8',
				tertiary: '#efddd1',
				accent: '#FCE583',
				// 'webshop-primary': '#56A6AD',

				// State
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',

				'cta-primary': '#EA412A',
				'cta-secondary': '#F9C6BF',
			}
		}
	},
	icons: {
		iconfont: 'md'
	}
});
