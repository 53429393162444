<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },

  data: () => ({
  }),
};
</script>
<style lang="scss">
body {
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

#app {
  background-image: linear-gradient(180deg, #BFE5E8 0%, #EFDDD1 100%);

  .logo-and-loader {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100%;
    }

  }
}
</style>

